import React from "react"
import styles from "./FormPage.module.css"
import { encode } from "./FormPage"
import { useForm } from "react-hook-form"

export const LetsDeal = () => {
  const { register, handleSubmit } = useForm()
  const [submitted, setSubmitted] = React.useState(false)
  const onSubmit = data => {
    console.log(data)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "letsdeal", ...data }),
    })
      .then(() => {
        console.log(encode({ "form-name": "letsdeal", ...data }))
        setSubmitted(true)
      })
      .catch(error => console.error(error))
  }
  if (submitted) {
    return (
      <div style={{ height: 500, margin: "64px", padding: 32 }}>
        <h1>Tack, vi hörs!</h1>
        <h3>
          Vi återkommer till dig inom kort för att bekräfta din fråga eller
          beställning. Med önskan om gott samarbete, hälsar vi dig välkommen
          till oss på Amins Trafikskola!
        </h3>
        <p>
          <a href="https://www.facebook.com/albinstrafik/" className="fbLink">
            <img
              src={require("./../../images/FB.png")}
              alt="facebook"
              width="42"
            />
          </a>
          Gilla oss på Facebook för att få bra erbjudanden, tips om lediga
          körlektioner och annan viktig information!
        </p>
      </div>
    )
  }

  return (
    <form
      name="letsdeal"
      method="POST"
      data-netlify="true"
      className={styles.formPage}
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1>Boka med Let's Deal-kod</h1>
      <label>
        Let's Deal-kod:
        <input
          type="text"
          name="letsdeal-code"
          required
          ref={register({ required: true })}
        />
      </label>
      <label>
        Namn:{" "}
        <input
          type="text"
          name="name"
          required
          ref={register({ required: true })}
        />
      </label>
      <label>
        Email:{" "}
        <input
          type="email"
          name="email"
          required
          ref={register({ required: true })}
        />
      </label>
      <label>
        Telefon:{" "}
        <input
          type="tel"
          name="tel"
          required
          ref={register({ required: true })}
        />
      </label>
      <label>
        Personnummer:{" "}
        <input
          type="text"
          name="personnummer"
          required
          ref={register({ required: true })}
        />
      </label>
      <label>
        Välj tid:{" "}
        <select name="time[]" required ref={register({ required: true })}>
          <option value="10:00">10:00</option>
          <option value="11:30">11:30</option>
          <option value="13:30">13:30</option>
          <option value="15:00">15:00</option>
          <option value="16:40">16:40</option>
        </select>
      </label>
      <label>
        Välj startvecka:{" "}
        <select name="startweek[]" required ref={register({ required: true })}>
          <option value="v16">Vecka 16, mån 15/4</option>
          <option value="v17">Vecka 17, mån 22/4</option>
          <option value="v18">Vecka 18, mån 29/4</option>
          <option value="v19">Vecka 19, mån 6/5</option>
          <option value="v20">Vecka 20, mån 13/5</option>
          <option value="v21">Vecka 21, mån 20/5</option>
          <option value="v22">Vecka 22, mån 27/5</option>
          <option value="v23">Vecka 23, mån 3/6</option>
          <option value="v24">Vecka 24, mån 10/6</option>
          <option value="v25">Vecka 25, mån 17/6</option>
        </select>
      </label>
      <label>
        Övrig info / Fråga:{" "}
        <textarea name="message" ref={register({ required: false })} />
      </label>

      <br />
      <p>
        <input
          type="checkbox"
          required
          style={{ height: "24px", width: "24px", marginRight: "8px" }}
        />
        Jag är införstådd med att för att boka kurser eller paket så låter jag
        Amins trafikskola hantera uppgifterna som hämtas in via formuläret, och
        godkänner den behandlingen av mina personuppgifter.
      </p>
      <input type="submit" value="Skicka" className={styles.submit} />
    </form>
  )
}
